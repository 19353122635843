import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Wrapper, Button } from "./checkout.styles"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const redirectToCheckout = async (event, plan) => {
  const id = event.target.id
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    items: [{ [plan ? "plan" : "sku"]: id, quantity: 1 }],
    successUrl: `https://stjohnsbloxwich.org.uk/thanks`,
    cancelUrl: `https://stjohnsbloxwich.org.uk/donate`,
  })

  if (error) {
    console.warn("Error:", error)
  }
}

const Checkout = () => {
  return (
    <>
      <Wrapper>
        <h2> One Off Donation</h2>
        <Button onClick={redirectToCheckout} id="sku_H4XN0gF66grlfj">
          £5
        </Button>
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H4XOv2OGGuzTyW"
        >
          £10
        </Button>
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H4XOtHoTBoQe1w"
        >
          £15
        </Button>{" "}
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H4XOQWyotHAFdC"
        >
          £20
        </Button>{" "}
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H4XOTkWMH1UGBZ"
        >
          £30
        </Button>{" "}
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H4XPFx44V01Y4h"
        >
          £50
        </Button>
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H7sQN6kN7RkKBL"
        >
          £75
        </Button>
        <Button
          onClick={event => redirectToCheckout(event)}
          id="sku_H7sQo7DAk5ckIE"
        >
          £100
        </Button>
      </Wrapper>
      <Wrapper>
        <h2> Regular Monthly Donation</h2>
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H4XRHeOaPlLJta"
        >
          £5
        </Button>
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H4XRHgYc9rG5sB"
        >
          £10
        </Button>
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H4XRqoVvtlJpDy"
        >
          £15
        </Button>{" "}
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H4XSYTXX4yskR0"
        >
          £20
        </Button>{" "}
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H4XSklHjv4NrBl"
        >
          £30
        </Button>{" "}
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H4XSyLdaQx20eW"
        >
          £50
        </Button>
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H7sKXB5ls5TDSS"
        >
          £75
        </Button>
        <Button
          onClick={event => redirectToCheckout(event, true)}
          id="plan_H7sKI2SNfCkluv"
        >
          £100
        </Button>
      </Wrapper>
    </>
  )
}

export default Checkout
