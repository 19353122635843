import styled from "@emotion/styled"
import { lightGrey, Fira, darkGrey, logoRed } from "../../globalCss"

export const Wrapper = styled.div`
  ${Fira};
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(auto-fill, 6.5rem);
  grid-gap: 1.5rem;
  padding: 2rem 5rem;

  h2 {
    grid-column: 1 / -1;
    color: ${logoRed};
    font-weight: bold;
    font-size 1.5rem;
    margin-bottom: .5rem;
  }

`
export const Button = styled.button`
  ${Fira}
  color: ${darkGrey};
  padding: 0;
  background-color: transparent;
  display: inline;
  border: 1px solid ${darkGrey};
  font-size: 2rem;
  letter-spacing: 0.1em;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${lightGrey};
    cursor: pointer;
  }
`
